import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Flex } from 'rebass'
import Link from '../Link'
import { RowFixed } from '../Row'

const TitleWrapper = styled.div`
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  z-index: 10;
`


const BombSwap = styled.div`
  font-size: 24px;
  display: flex;


  div:first-child {
    display: inline;
    color: ${({ theme }) => theme.primary1}
  }
`

export default function Title() {
  const history = useHistory()

  return (
    <TitleWrapper onClick={() => history.push('/')}>
      <Flex alignItems="center">
        <RowFixed>
          {/* <UniIcon id="link" onClick={() => history.push('/')}>
            BombSwap
          </UniIcon> */}
          <BombSwap>
            <div>Bomb</div>
            <div>Swap</div>
          </BombSwap>
        </RowFixed>
      </Flex>
    </TitleWrapper>
  )
}
