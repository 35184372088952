import React from 'react'
import styled, { css } from 'styled-components'
import { Spinner } from '@fbomb-finance/uikit'
import { useDarkModeManager } from '../../contexts/LocalStorage'


const Loader = styled.div`
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  & > * {
    width: 130px;
  }
  ${props =>
    props.fill && !props.height
      ? css`
          height: 100vh;
        `
      : css`
          height: 180px;
        `}
`

const LocalLoader = ({ fill }) => {
  // const [darkMode] = useDarkModeManager()

  return (
    <Loader fill={fill}>
      <Spinner size="64px" />
    </Loader>
  )
}

export default LocalLoader
